/* cyrillic-ext */
@font-face {
  font-family: '__PT_Serif_1a33f8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4cefbb0c18f21ca5-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__PT_Serif_1a33f8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/75655d6639acf9be-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__PT_Serif_1a33f8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8dcff538116e20e3-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__PT_Serif_1a33f8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/886f446b96dc7734-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__PT_Serif_Fallback_1a33f8';src: local("Times New Roman");ascent-override: 94.22%;descent-override: 25.93%;line-gap-override: 0.00%;size-adjust: 110.28%
}.__className_1a33f8 {font-family: '__PT_Serif_1a33f8', '__PT_Serif_Fallback_1a33f8';font-weight: 400;font-style: normal
}.__variable_1a33f8 {--font-serif: '__PT_Serif_1a33f8', '__PT_Serif_Fallback_1a33f8'
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_ccb621';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3e7f31236d01f7e0-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_ccb621';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d3f1dc0f80085d05-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_ccb621';src: local("Arial");ascent-override: 94.90%;descent-override: 29.66%;line-gap-override: 0.00%;size-adjust: 104.53%
}.__className_ccb621 {font-family: '__DM_Sans_ccb621', '__DM_Sans_Fallback_ccb621';font-weight: 400;font-style: normal
}.__variable_ccb621 {--font-sans: '__DM_Sans_ccb621', '__DM_Sans_Fallback_ccb621'
}

/* latin-ext */
@font-face {
  font-family: '__DM_Mono_d94f73';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/67cc401a1d600c37-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Mono_d94f73';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/edb9f1eb1c1a7ead-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Mono_Fallback_d94f73';src: local("Arial");ascent-override: 73.71%;descent-override: 23.03%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_d94f73 {font-family: '__DM_Mono_d94f73', '__DM_Mono_Fallback_d94f73';font-weight: 400;font-style: normal
}.__variable_d94f73 {--font-mono: '__DM_Mono_d94f73', '__DM_Mono_Fallback_d94f73'
}

